import React, { useState } from "react"
import {Link, navigate} from "gatsby";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { GoLinkExternal } from "react-icons/go"
import { object, string, setLocale } from "yup";

import Layout from "../../containers/Layout";
import Meta from "../../containers/Meta";
import PageHeader from "../../components/PageHeader";
import BackToTop from "../../components/BackToTop";

import * as styles from "../../sass/contact.module.scss";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvIkfvw8sYL9RgxHWlYT4LK0HaN9Xc60Q",
  authDomain: "mamoru-app.firebaseapp.com",
  projectId: "mamoru-app",
  storageBucket: "mamoru-app.appspot.com",
  messagingSenderId: "939724879651",
  appId: "1:939724879651:web:886430922244ccc24f7e96",
  measurementId: "G-WNRPVJ2JJ1"
};
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const ContactPage = () => {
  setLocale({
    string: {
      email: 'メールアドレスの形式が正しくありません',
      required: '必ず入力してください'
    }
  });
  const schema = object({
    name: string().required(),
    email: string().email().required(),
    content: string().required()
  });

  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    setLoading(true);
    const contact = httpsCallable(functions, 'contact');
    contact(data).then(result => {
      navigate("/contact/complete");
      setLoading(false);
    })
  };

  return (
    <Layout>
      <Meta title={"お問い合わせ"} />

      <PageHeader title={"お問い合わせ"} />

      <div className={styles.content}>
        <div className={styles.formContainer}>
          <p>
            お問い合わせいただき誠にありがとうございます。<br />
            すべての項目にご記入の上「送信する」をタップしてください。<br />
            送信いただいた情報はお問い合わせの回答のみに使用し、それ以外の目的では利用いたしません。<br />
            詳しくは<Link to="/privacy">プライバシーポリシー <GoLinkExternal /></Link>をご覧ください。
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.formRow} ${errors.name ? styles.hasError : ''}`}>
              <label htmlFor="text">お名前</label>
              <input id="text" type="text" className={styles.formControl} placeholder="氏名を入力してください" {...register("name", {required: true})} />
              {errors.name && (
                <div className={styles.formError}>{errors.name?.message}</div>
              )}
            </div>

            <div className={`${styles.formRow} ${errors.email ? styles.hasError : ''}`}>
              <label htmlFor="email">メールアドレス</label>
              <input id="email" type="email" className={styles.formControl} placeholder="info@mamoru-bus.com" {...register("email", {required: true})} />
              {errors.email && (
                <div className={styles.formError}>{errors.email?.message}</div>
              )}
            </div>

            <div className={styles.formRow}>
              <label htmlFor="content">お問い合わせ内容</label>
              <textarea id="content" className={styles.formControl} rows={8} placeholder="お問い合わせ内容を入力してください" {...register("content", {required: true})} />
            </div>

            <div className={styles.formAction}>
              <button type="submit" className={styles.btn} disabled={isLoading}>
                {isLoading ? '送信しています' : '送信する'}
              </button>
            </div>
          </form>
        </div>
      </div>

      <BackToTop />
    </Layout>
  );
};

export default ContactPage;